import React from "react";
import Layout from "../../components/layout";
import { Helmet } from 'react-helmet';
import data from '../../data/how-it-works'
import { Link } from 'gatsby';
import NACAB from '../resources/NA_CAB-Pressure-Cab-Protection-Levels.pdf'

export default () => {
    return <Layout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>How It Works | Clean Air Filter</title>
        </Helmet>
        <section>
            <h1><span className="tail"><span className="head">{data.content.section1.h1.head}</span> {data.content.section1.h1.tail}</span></h1>
            
            <article className="w-60">
                <p dangerouslySetInnerHTML={{ __html: data.content.section1.p1 }}></p>   
                <p>{data.content.section2.p}</p>
                <ol>
                    {data.content.section2.ul.map((item, index) => <li key={index}><p dangerouslySetInnerHTML={{ __html: item }}></p></li>)}
                </ol>
                <p><a className="download-link" target='_blank' rel="noreferrer" href={NACAB}>Cab pressure is not an indication of Cab Protection Levels</a></p>
                <p>{data.content.section3.p1}</p>

            </article>
        </section>
    </Layout>
}