import airFilter from './air-filters'
export default {
  slug: "how-it-works",
  title: "How It Works",
  content: {
    section1: {
      h1: {
        head: "Quality Tested to",
        tail: "Exceed Standards.",
      },
      p1:
        "Clean Air Filter has developed particulate/vapor filters with excellent organic vapor adsorption capabilities. Cross comparison to any specific vapor must consider the test stimulant, cyclohexane. The objective is to offer the operator of the cab the cleanest temperature controlled work environment possible.",
    },
    section2: {
      p:
        "Operator Protection Level Parameters and Field Testing Criteria used by CAF:",
      ul: [
        "Operator Protection Level testing is based on 98% total cab efficiency @ 0.3µm",
        "Providing the operator with a minimum of 17.66 CFM (30 m³/h) of clean air at all times",
        "Reducing leaks in the negative air plenum of the HVAC, maximum 2% total",
        "Cab Pressurization – whether high or low, cab pressure is NOT an indication of safe operator protection levels.",
      ],
    },
    section3: {
      p1: `Once a cab is field-tested for protection levels with Optical Particle Counters and/or CO2, the ratio can be used as an indication of cab performance. Once calibrated, the DPG will give signs of filter loading, cab seal degradation, electrical changes, improper filter installation, etc.`,
    },
  },
}